import { useState, useEffect } from "react";

export default function useWindowSize() {
	const [windowSize, setWindowSize] = useState({});

	function changeWindowSize() {
		setWindowSize({ width: window.innerWidth, height: window.innerHeight });
	}

	useEffect(() => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
		window.addEventListener("resize", changeWindowSize);
		return () => {
			window.removeEventListener("resize", changeWindowSize);
		};
	}, []);

	return { ...windowSize, isMobile: windowSize.width < 1200, isDesktop: windowSize.width >= 1200 };
}
